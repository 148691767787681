<template>
  <v-container class="grey lighten-5">
    <div id="app" class="maxwidth">
      <v-app app>
        <v-app-bar color="accent" app>
          <router-link
            style="text-decoration: none; color: #333"
            :to="{ name: 'Walk', params: { id: user.lastWalkID } }"
          >
            <v-app-bar-nav-icon>
              <v-icon color="white">mdi-arrow-left</v-icon>
            </v-app-bar-nav-icon>
          </router-link>
          <v-toolbar-title class="white--text">Chat </v-toolbar-title>
        </v-app-bar>
        <v-container class="fill-height">
          <v-row class="fill-height pb-14" align="end">
            <v-col>
              <div
                v-for="(item, index) in messages.slice().reverse()"
                :key="index"
              >
                <div
                  style="font-size: 12px"
                  :class="[
                    'd-flex flex-row align-center mx-0 my-0 pr-2',
                    item.senderID == senderID ? 'justify-end' : null,
                  ]"
                >
                  {{ item.timestamp | momentTime }}
                </div>
                <div
                  :class="[
                    'd-flex flex-row align-center',
                    item.senderID == senderID ? 'justify-end' : null,
                  ]"
                >
                  <div
                    v-if="item.senderID == senderID"
                    class="primary--text ma-3 pa-2"
                    style="background: #efefef; border-radius:7px; min-width:80px"
                  >
                    {{ item.messageText }}
                  </div>
                  <div
                    v-if="item.senderID != senderID"
                    class="accent--text darken-4 pa-2"
                    style="background: #efefef; border-radius:7px; min-width:80px"
                  >
                    {{ item.messageText }}
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-footer fixed>
          <v-container class="ma-0 pa-0">
            <v-row no-gutters>
              <v-col>
                <div class="d-flex flex-row align-center">
                  <v-text-field
                    v-model="msg"
                    placeholder="Stuur een bericht"
                    @keypress.enter="pushMessage"
                  ></v-text-field>
                  <v-btn icon class="ml-4" @click.prevent="pushMessage"
                    ><v-icon color="primary">mdi-send</v-icon></v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-footer>
      </v-app>
    </div>
  </v-container>
</template>
<style scoped>
.maxwidth {
  max-width: 400px;
}
</style>
<script>
import { mapGetters } from "vuex";
import { conversationsRef } from "../store/firestore";
import * as moment from "moment/moment";
//import router from "../router"

//  delete message
//  gelezen of niet en notificatie
export default {
  name: "Chat",
  data: () => ({
    heading: "Chat",
    msg: "",
    conversationId: "",
    messages: [],
    senderID: "",
    receiverID: "",
  }),
  created() {
    if (this.$route.params.id) {
      this.conversationId = this.$route.params.id;
      this.getMessages(this.conversationId);
    } else {
      this.heading = "Chat niet gevonden";
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD-M-YYYY");
    },
    momentTime: function (date) {
      return moment(date).format("HH:mm op DD-M-YYYY");
    },
  },

  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      matches: "matches",
    }),
  },
  methods: {
    getMessages(chatId) {
      this.splitString(chatId);
      var that = this;
      conversationsRef
        // haal drie messages uit de conversatie ID, orderby time, reverse, max 5
        .doc(chatId)
        .collection("messages")
        .orderBy("timestamp", "desc")
        .limit(5)
        .onSnapshot(function (snapshot) {
          if (snapshot) {
            that.messages.length = 0;
            snapshot.docs.forEach((doc) => {
              that.messages.push(doc.data());
            });
          } else {
            console.log("No such document!!");
          }
        });
    },
    splitString(str) {
      var middle = Math.ceil(str.length / 2);
      var s1 = str.slice(0, middle);
      var s2 = str.slice(middle);
      if (this.user.data.uid == s1) {
        this.senderID = s1;
        this.receiverID = s2;
      } else {
        this.senderID = s2;
        this.receiverID = s1;
      }
    },

    pushMessage() {
      var now = Date.now();
      var data = {
        senderID: this.senderID,
        receiverID: this.receiverID,
        messageText: this.msg,
        timestamp: now,
      };
      if (this.msg !== "") {
        conversationsRef
          .doc(this.conversationId)
          .collection("messages")
          .doc()
          .set(data);
      }
      this.msg = "";
    },
  },
};
</script>